.App {
  text-align: center;
  min-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


